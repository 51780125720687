import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OnboardingStep1.css";

const OnboardingStep1 = () => {
  const navigate = useNavigate();

  const onContinueContainerClick = useCallback(() => {
    navigate("/onboarding-step-3");
  }, [navigate]);

  return (
    <div className="onboarding-step-2">
      <div className="component-26-1">
        <div className="component-26-1-child" />
        <img className="image-21-icon" alt="" src="/image-21@2x.png" />
      </div>
      <div className="component-28-1">
        <div className="component-26-1-child" />
        <img className="image-21-icon" alt="" src="/image-21@2x.png" />
      </div>
      <div className="component-29-1">
        <div className="component-26-1-child" />
        <img className="image-25-icon" alt="" src="/image-25@2x.png" />
      </div>
      <div className="component-27-1">
        <div className="component-26-1-child" />
        <img className="image-23-icon" alt="" src="/image-23@2x.png" />
      </div>
      <div className="component-30-1">
        <div className="component-26-1-child" />
        <img className="image-23-icon" alt="" src="/image-23@2x.png" />
      </div>
      <div className="step-2-of-container">
        <p className="step-2-of">Step 2 of 3</p>
        <p className="step-2-of">Brand Profile</p>
      </div>
      <div className="continue4" onClick={onContinueContainerClick}>
        <div className="rectangle-div" />
        <b className="proceed2">Proceed</b>
      </div>
      <div className="stylco2">
        <span>stylc</span>
        <span className="o1">o</span>
      </div>
      <div className="component-26-2">
        <div className="component-26-1-child" />
        <img className="image-21-icon" alt="" src="/image-21@2x.png" />
      </div>
    </div>
  );
};

export default OnboardingStep1;
