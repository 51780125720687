import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OnboardingStep2.css";

const OnboardingStep2 = () => {
  const navigate = useNavigate();

  const onContinueContainerClick = useCallback(() => {
    navigate("/recommended-feed");
  }, [navigate]);

  return (
    <div className="onboarding-step-3">
      <div className="step-3-of-container">
        <p className="step-3-of">Step 3 of 3</p>
        <p className="step-3-of">Style Profile</p>
      </div>
      <div className="continue5" onClick={onContinueContainerClick}>
        <div className="continue-child1" />
        <b className="submit">Submit</b>
      </div>
      <div className="stylco3">
        <span>stylc</span>
        <span className="o2">o</span>
      </div>
      <div className="component-30-2">
        <img className="image-27-icon" alt="" src="/image-27@2x.png" />
      </div>
      <div className="classic">
        <p className="step-3-of">Classic</p>
      </div>
      <img className="image-28-icon" alt="" src="/image-28@2x.png" />
      <div className="glam">
        <p className="step-3-of">Glam</p>
      </div>
      <img className="image-29-icon" alt="" src="/image-29@2x.png" />
      <div className="boho">
        <p className="step-3-of">Boho</p>
        <p className="step-3-of">&nbsp;</p>
      </div>
      <img className="image-30-icon" alt="" src="/image-30@2x.png" />
      <div className="casual">
        <p className="step-3-of">Casual</p>
      </div>
    </div>
  );
};

export default OnboardingStep2;
