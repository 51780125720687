import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./LandingScreenOption.css";

const LandingScreenOption = () => {
  const navigate = useNavigate();

  const onProceedTextClick = useCallback(() => {
    navigate("/landing-screen-option-2");
  }, [navigate]);

  return (
    <div className="landing-screen-option-1">
      <img className="image-11-icon" alt="" src="/image-11@2x.png" />
      <div className="continue">
        <div className="continue-child" />
        <b className="proceed" onClick={onProceedTextClick}>
          Proceed
        </b>
      </div>
      <div className="landing-screen-option-1-child" />
      <b className="stylco">StylCo</b>
    </div>
  );
};

export default LandingScreenOption;
