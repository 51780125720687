import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OnboardingStep3.css";

const OnboardingStep3 = () => {
  const navigate = useNavigate();

  const onRectangleClick = useCallback(() => {
    navigate("/onboarding-step-2");
  }, [navigate]);

  return (
    <div className="onboarding-step-11">
      <img className="component-18-1" alt="" src="/component-18--1.svg" />
      <img className="path-309-icon" alt="" src="/path-309.svg" />
      <img className="component-20-1" alt="" src="/component-20--1.svg" />
      <img className="component-19-1" alt="" src="/component-19--1.svg" />
      <img className="component-21-1" alt="" src="/component-21--1.svg" />
      <img className="component-22-1" alt="" src="/component-22--1.svg" />
      <img className="component-17-1" alt="" src="/component-17--1.svg" />
      <div className="continue6">
        <div className="continue-child2" onClick={onRectangleClick} />
        <b className="proceed3">Proceed</b>
      </div>
      <div className="select-body-profile-container">
        <p className="select-body-profile">Select Body Profile</p>
      </div>
    </div>
  );
};

export default OnboardingStep3;
