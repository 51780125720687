import "./RecommendedFeed.css";

const RecommendedFeed = () => {
  return (
    <div className="recommended-feed">
      <div className="component-24-1">
        <div className="large-card">
          <div className="large-card-child" />
          <img className="large-card-item" alt="" src="/rectangle-216@2x.png" />
          <b className="nylon-jersey-crop">Nylon Jersey Crop Top</b>
        </div>
        <b className="c-1250">C$ 1,250</b>
      </div>
      <div className="menus-wrapper">
        <div className="large-card">
          <div className="menus-child" />
          <div className="menus-item" />
          <img className="path-415-icon" alt="" src="/path-415.svg" />
          <div className="shop-recommended">Shop Recommended</div>
        </div>
      </div>
      <div className="stylco4">
        <span>stylc</span>
        <span className="o3">o</span>
      </div>
      <div className="component-24-2">
        <div className="large-card">
          <div className="large-card-child" />
          <img className="large-card-item" alt="" src="/rectangle-216@2x.png" />
          <b className="nylon-jersey-crop">Nylon Jersey Crop Top</b>
        </div>
        <b className="c-1250">C$ 1,250</b>
      </div>
    </div>
  );
};

export default RecommendedFeed;
