import "./Group.css";

const Group = () => {
  return (
    <div className="line-parent">
      <img className="group-item" alt="" src="/line-44.svg" />
      <img className="group-inner" alt="" src="/line-45.svg" />
    </div>
  );
};

export default Group;
