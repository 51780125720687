import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./LandingScreenOption1.css";

const LandingScreenOption1 = () => {
  const navigate = useNavigate();

  const onContinueContainerClick = useCallback(() => {
    navigate("/onboarding-step-1");
  }, [navigate]);

  return (
    <div className="landing-screen-option-2">
      <img className="image-12-icon" alt="" src="/image-12@2x.png" />
      <div className="stylco1">
        <span>stylc</span>
        <span className="o">o</span>
      </div>
      <div className="landing-screen-option-2-child" />
      <div className="youdefineyou">you.define.you.</div>
      <div className="discover">discover</div>
      <div className="shop">shop</div>
      <div className="continue1" onClick={onContinueContainerClick}>
        <div className="continue-item" />
        <b className="proceed1">Proceed</b>
      </div>
    </div>
  );
};

export default LandingScreenOption1;
