import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingScreenOption from "./pages/LandingScreenOption";
import LandingScreenOption1 from "./pages/LandingScreenOption1";
import OnboardingStep from "./pages/OnboardingStep";
import OnboardingStep1 from "./pages/OnboardingStep1";
import OnboardingStep2 from "./pages/OnboardingStep2";
import OnboardingStep3 from "./pages/OnboardingStep3";
import RecommendedFeed from "./pages/RecommendedFeed";
import Group from "./pages/Group";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/landing-screen-option-2":
        title = "";
        metaDescription = "";
        break;
      case "/onboarding-step-1":
        title = "";
        metaDescription = "";
        break;
      case "/onboarding-step-2":
        title = "";
        metaDescription = "";
        break;
      case "/onboarding-step-3":
        title = "";
        metaDescription = "";
        break;
      case "/onboarding-step-11":
        title = "";
        metaDescription = "";
        break;
      case "/recommended-feed":
        title = "";
        metaDescription = "";
        break;
      case "/group-347":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingScreenOption />} />
      <Route
        path="/landing-screen-option-2"
        element={<LandingScreenOption1 />}
      />
      <Route path="/onboarding-step-1" element={<OnboardingStep />} />
      <Route path="/onboarding-step-2" element={<OnboardingStep1 />} />
      <Route path="/onboarding-step-3" element={<OnboardingStep2 />} />
      <Route path="/onboarding-step-11" element={<OnboardingStep3 />} />
      <Route path="/recommended-feed" element={<RecommendedFeed />} />
      <Route path="/group-347" element={<Group />} />
    </Routes>
  );
}
export default App;
