import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OnboardingStep.css";

const OnboardingStep = () => {
  const navigate = useNavigate();

  const onContinueContainer1Click = useCallback(() => {
    navigate("/onboarding-step-11");
  }, [navigate]);

  return (
    <div className="onboarding-step-1">
      <div className="continue2">
        <div className="continue-inner" />
        <b className="already-have-an">ALREADY HAVE AN ACCOUNT</b>
      </div>
      <img className="image-13-icon" alt="" src="/image-13@2x.png" />
      <div className="step-1-of-container">
        <p className="step-1-of">Step 1 of 3</p>
        <p className="step-1-of">Body Profile</p>
      </div>
      <div className="continue3" onClick={onContinueContainer1Click}>
        <div className="group-parent">
          <div className="continue-inner" />
          <b className="take-a-body">{` TAKE A BODY PROFILE SURVEY `}</b>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep;
